<template>
    <div>
        Redirecting....
    </div>
</template>
<script>
    import config from '@/mixins/config'
    import password from '@/api/password'
    export default {
        name: 'TokenLoginRoute',
        mixins: [ config ],
        created() {
            const code = this.$route.params.code
            if (code) {
                password.tokenLogin(code).then((res) => {
                    this.$store.commit('login', res.data.token)
                    this.$store.dispatch('refreshCurrentUser')
                    this.$router.push('/home')
                })
            }
        }
    }
</script>
